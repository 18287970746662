import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Rumble } from 'app/components/Icons'
import React, { memo } from 'react'

export interface Props {
  description?: string
  title?: string
  variant?: Variant
}

export const Intro = memo(function Intro({
  description,
  title,
  variant = 'default',
}: Props) {
  if (!description && !title) {
    return null
  }

  return (
    <Container variant={variant}>
      {variant === 'default' ? (
        <>
          <Rumble />
          <Rumble />
        </>
      ) : null}

      {title ? (
        <FadeInUp>
          <Title variant={variant}>{title}</Title>
        </FadeInUp>
      ) : null}
      {description ? (
        <FadeInUp>
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section<ContainerProps>`
  max-width: 44.0625rem;
  margin: 13.3125rem auto 0;
  padding: 0 1.875rem;
  position: relative;
  text-align: center;

  svg {
    position: absolute;
    &:first-of-type {
      width: auto;
      height: 8.4375rem;
      fill: none;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight1};
      stroke-width: 0.3;
      top: -4.5rem;
      left: calc(50% + 4rem);
    }
    &:last-of-type {
      width: auto;
      height: 19.125rem;
      fill: none;
      stroke: ${({ theme }) => theme.colors.variants.neutralLight1};
      stroke-width: 0.15;
      top: -0.4375rem;
      left: calc(50% - 2.875rem);
      transform: translateX(-50%);
    }

    @media (max-width: 1023px) {
      display: none;
    }
  }

  @media (max-width: 1023px) {
    max-width: none;
    margin-top: 5rem;
  }
`

const Title = styled.h2<ContainerProps>`
  max-width: 33.125rem;
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  letter-spacing: 0.125rem;
  line-height: 2.75rem;
  margin: auto;
  position: relative;
  text-transform: uppercase;

  ${({ variant }) => {
    switch (variant) {
      case 'simple':
        return css`
          max-width: 44.375rem;
        `
    }
  }}

  @media (max-width: 767px) {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 2rem;
  margin-top: 1.875rem;
  position: relative;

  p {
    margin-block-end: 1em;
    &:last-of-type {
      margin-block-end: 0;
    }
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'simple'
